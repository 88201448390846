body {
    font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
.card-body>.card-title {
    font-family: 'Open Sans', sans-serif;
}

.card-title {
    font-weight: 500;
    font-size: 1.25rem;
}

h2 {
    color: #000;
}

.card-img-overlay .card-title {
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
}

.row-content {
    border-bottom: 1px ridge;
    min-height: 200px;
}

.site-footer {
    width: 100%;
    color: #fff;
    background-color: #000;
    margin-top: 2rem;
}

.footer-row {
    padding: 25px;
}

.navbar-brand {
    max-width: 4rem;
    margin-right: 2rem;
}

.navbar-brand img {
    width: 100%;
    height: 100%;
    margin-right: 2rem;
    max-width: 4rem;
}

.navbar-dark {
    background-color: #000;
    margin-bottom: 2rem;
    min-height: 8rem;
}

.page-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.page-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: 300ms ease-out;
}

.page-exit {
    opacity: 1;
}

.page-exit-active {
    opacity: 0;
    transition: 300ms ease-in;
}

.coming-soon {
    margin: auto;
    padding: 10px;
    width: 80%;
    border: 5px solid #000;
    text-align: center;
}
